import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="SOCIAL MEDIA CLONE"
          des=" A social media project involves creating and managing a campaign, platform, or system that utilizes social media channels (like Facebook, Instagram, Twitter, LinkedIn, TikTok, etc.) to achieve specific goals. These goals could range from increasing brand awareness, engaging with an audience, driving traffic to a website, or promoting a cause or service."
          src={projectOne}
        />
        <ProjectsCard
          title="E-commerce Website"
          des=" An eCommerce website is an online platform that allows businesses to sell goods or services to customers over the internet. These websites enable transactions such as product browsing, shopping cart management, payment processing, and order fulfillment."
          src={projectTwo}
        />
        <ProjectsCard
          title="Chatting App"
          des="A chatting app project involves developing an application that allows users to send and receive messages in real time. These types of apps can vary widely in terms of features and platforms, from basic text messaging apps to more complex platforms with multimedia sharing, voice, and video calls, and even group chats. Below is a brief breakdown of the key components and steps involved in creating a chatting app project:"
          src={projectThree}
        />
        <ProjectsCard
          title="Gym Trainer App"
          des=" Gym Trainer Project is a digital platform or application designed to help gym trainers manage their clients, track their fitness progress, create personalized workout plans, and provide nutritional guidance. The project can either be a web-based application or a mobile app. It can offer a range of features for both trainers and clients to enhance the fitness experience."
          src={projectThree}
        />
        <ProjectsCard
          title="E-commerce Website"
          des=" A Real Estate Project refers to the development or creation of a digital platform that helps users buy, sell, rent, or manage real estate properties. The project can be a website, mobile app, or both, designed to connect real estate agents, buyers, sellers, and renters in a seamless manner. The goal is to streamline property transactions, improve property visibility, and provide valuable tools for managing properties."
          src={projectOne}
        />
        <ProjectsCard
          title="Blog Project"
          des=" A Blog Project is the creation and development of a blogging platform or website where users can publish, share, and manage articles or posts on various topics. The goal of the blog project is typically to engage readers, share information, build an audience, or even monetize content through advertisements, affiliate marketing, or sponsored posts. A blogging platform can be personal (for individual bloggers) or business-oriented (for content marketing, SEO, etc.)."
          src={projectTwo}
        />
      </div>
    </section>
  );
}

export default Projects