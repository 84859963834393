import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Kalyan</h3>
        <p className="text-lg font-normal text-gray-400">
          Digital Marketing Expert
        </p>
        <p className="text-base text-gray-400 tracking-wide">
           I am Digital Expert and i can handle all digital marketing things,website design and graphics design .
        </p>
       
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">ceo@kldigital.in</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
        <span className="bannerIcon">
           <a href="https://www.facebook.com/kalyandigital1">
           <FaFacebookF />
           </a>
            
          </span>
          <span className="bannerIcon">
            <a href='https://x.com/KalyanDigital09'>
            <FaTwitter />

            </a>
           
          </span>
          <span className="bannerIcon">
          <a href=" https://www.linkedin.com/in/kalyan-digital-marketing-5314a4335">
          <FaLinkedinIn />
          </a>
          </span>
          <span className="bannerIcon">
          <a href="https://www.instagram.com/kalyandigital1/profilecard/?igsh=MW9lazBoMjNrdjViZA==">
          <FaInstagram />
          </a>
          </span>
          <span className="bannerIcon">
          <a href="https://youtube.com/@kalyandigitalmarketing2024?feature=shared">
          <FaYoutube/>
          </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft