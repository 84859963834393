import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2007 - 2010</p>
          <h2 className="text-3xl md:text-4xl font-bold">Company Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Freelance Upwork Experience."
            subTitle="Marketing Experience."
            result="Success"
            des="Upwork has given me the freedom to do something that makes me feel truly accomplished and proud, all while enjoying a lifestyle I’d always dreamed of and spending lots of quality time with my partner."
          />
          <ResumeCard
            title="Freelance Fiver Experience."
            subTitle="Graphics Experience."
            result="Success"
            des="Fiver has given me the freedom to do something that makes me feel truly accomplished and proud, all while enjoying a lifestyle I’d always dreamed of and spending lots of quality time with my partner."
          />
          <ResumeCard
            title="Freelance Flexjob Experience."
            subTitle="Web development Experience."
            result="Success"
            des="Flexjob has given me the freedom to do something that makes me feel truly accomplished and proud, all while enjoying a lifestyle I’d always dreamed of and spending lots of quality time with my partner."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2007 - 2010</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Freelance Digital Marketing Expert."
            subTitle="Self Employed - (2017 - Present)."
            result="Success"
            des="To Handle Multiple Client's Requirements"
          />
          <ResumeCard
            title="Freelance Web Developer & Trainer."
            subTitle="Self Employed- (2012 - 2016)."
            result="Success"
            des="To Handle Multiple Client's Requirements about the Developement and designing"
          />
          <ResumeCard
            title="Freelance Graphics Designer."
            subTitle="Self Employed- (2009 - 2011)."
            result="Success"
            des="To Handle Multiple Client's Requirements about the videos editing and motion graphics"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Achievement;
