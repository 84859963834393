import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe} from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Business Stratagy",
    des: "It refers to the plan of action a company takes to achieve its long-term goals, compete effectively in the market, and ensure sustainable growth. It outlines how a business intends to utilize its resources, capabilities, and strengths to meet customer needs, outperform competitors, and maximize profitability.",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "App Development",
    des: "It refers to the process of designing, building, and deploying software applications that run on mobile devices (smartphones and tablets), desktops, or other digital platforms. It involves a range of activities including planning, coding, testing, and maintenance to create an application that is functional, user-friendly, and reliable.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "SEO Optimisation",
    des: "SEO (Search Engine Optimization) is the practice of optimizing a website or online content to improve its visibility in search engine results pages (SERPs), ultimately driving more organic (non-paid) traffic. SEO involves a combination of strategies and techniques designed to enhance the ranking of a website on search engines like Google, Bing, or Yahoo.",
  },
  {
    id: 4,
    icon: <FaMobile />,
    title: "Mobile Development",
    des: "Mobile Development refers to the process of creating software applications that run on mobile devices, such as smartphones and tablets. It includes the development of both mobile applications (apps) and mobile websites, with a focus on delivering user-friendly, efficient, and functional experiences tailored for mobile users.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "UX Design",
    des: "UX (User Experience) Designer focuses on optimizing the interaction between users and products, primarily digital ones like websites, mobile apps, or software applications. The goal of a UX designer is to ensure that users have a positive, intuitive, and efficient experience when interacting with a product.",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Hosting Websites",
    des: "Website hosting refers to the service that allows individuals and organizations to store their website files (HTML, images, scripts, etc.) on a server, making them accessible to users via the internet. It is a crucial part of creating and maintaining a website, as without hosting, the website files would not be available online.",
  },
];
