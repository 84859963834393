import React from 'react'
import { FaFacebookF, FaLinkedinIn, FaReact, FaInstagram, FaYoutube } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs } from "react-icons/si";
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
          <span className="bannerIcon">
           <a href="https://www.facebook.com/kalyandigital1">
           <FaFacebookF />
           </a>
            
          </span>
        
          <span className="bannerIcon">
          <a href="https://www.linkedin.com/in/kalyan-digital-marketing-5314a4335">
          <FaLinkedinIn />
          </a>
          </span>
          <span className="bannerIcon">
          <a href="https://www.instagram.com/kalyandigital1/profilecard/?igsh=MW9lazBoMjNrdjViZA==">
          <FaInstagram />
          </a>
          </span>
          <span className="bannerIcon">
          <a href="https://youtube.com/@kalyandigitalmarketing2024?feature=shared">
          <FaYoutube/>
          </a>
          </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiNextdotjs />
            </span>
            <span className="bannerIcon">
              <SiTailwindcss />
            </span>
            <span className="bannerIcon">
              <SiFigma />
            </span>
          </div>
        </div>
      </div>
  )
}

export default Media